const list = [
    // {
    //     path: '/school',
    //     name: '学校课程表',
    //     redirect: '/week',
    //     component: () => import('@/views/layout.vue'),
    //     children: [
    //         {
    //             path: '/week',
    //             name: '学期周时管理',
    //             meta: { title: "学期周时管理", path: "/week" },
    //             component: () => import('@/views/week.vue')
    //         },
    //         {
    //             path: '/course',
    //             name: '课程管理',
    //             meta: { title: "课程管理", path: "/course" },
    //             component: () => import('@/views/course.vue')
    //         },
    //         {
    //             path: '/timetable',
    //             name: '课程表管理',
    //             meta: { title: "课程表管理", path: "/timetable" },
    //             component: () => import('@/views/timetable.vue')
    //         },

    //     ]
    // },
    // {
    //     path: '/freeApi',
    //     name: '免费api',
    //     redirect: '/superpower',
    //     component: () => import('@/views/layout.vue'),
    //     children: [
    //         {
    //             path: '/superpower',
    //             name: '超能力管理',
    //             meta: { title: "超能力管理", path: "/superpower" },
    //             component: () => import('@/views/superpower.vue')
    //         }

    //     ]
    // },
    {
        path: '/suggest',
        name: '投诉与建议',
        redirect: '/suggest/index',
        component: () => import('@/views/layout.vue'),
        children: [
            {
                path: '/suggest/index',
                name: '投诉与建议',
                meta: { title: "投诉与建议", path: "/suggest/index" },
                component: () => import('@/views/suggest.vue')
            }

        ]
    },
    {
        path: '/account',
        name: '用户管理',
        redirect: '/account/index',
        component: () => import('@/views/layout.vue'),
        children: [
            {
                path: '/account/index',
                name: '用户列表',
                meta: { title: "用户列表", path: "/account/index" },
                component: () => import('@/views/account.vue')
            }

        ]
    },
]

export default list