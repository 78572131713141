import { createRouter, createWebHashHistory } from 'vue-router'
import Layout from '@/views/layout.vue';
import list from "./routes"
// const routes = [
//   {
//     path: '/',
//     name: '首页',
//     redirect: '/home',
//     component: Layout,
//     children: [
//       {
//         path: '/home',
//         name: '首页',
//         meta: { title: "首页", path: "/home" },
//         component: () => import('../views/home.vue')
//       }]
//   },
//   {
//     path: '/login',
//     name: 'login',
//     component: () => import('@/views/login.vue')
//   },
//   ...list
// ]
const routes = [
  // {
  //   path: '/',
  //   name: '首页',
  //   redirect: '/home',
  //   component: Layout,
  //   children: [
  //     {
  //       path: '/home',
  //       name: '首页',
  //       meta: { title: "首页", path: "/home" },
  //       component: () => import('../views/home.vue')
  //     }]
  // },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/login.vue')
  },
  ...list

]
const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes
})
router.beforeEach((to, from, next) => {
  let token = localStorage.getItem("token")
  if (!token && to.path != "/login") {
    next('/login');
  } else {
    next(); // 继续当前的导航
  }
});
export default router
