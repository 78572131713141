<template>
  <a-layout style="min-height: 100vh">
    <a-layout-sider v-model:collapsed="collapsed" collapsible>
      <h3>匕匕价后台管理</h3>
      <a-menu v-model:selectedKeys="selectedKeys" theme="dark" mode="inline">
        <!-- <a-menu-item key="1" @click="navTo('/')">
          <AccountBookOutlined />
          <span>学期管理</span>
        </a-menu-item>
        <a-menu-item key="2" @click="navTo('/course')">
          <BookOutlined />
          <span>课程管理</span>
        </a-menu-item> -->
        <!-- <a-menu-item key="/home" @click="navTo('/home')">
          <HomeOutlined />
          <span>首页</span>
        </a-menu-item> -->
        <a-sub-menu
          v-for="item in menuItems"
          :key="item.path"
          :title="item.name"
        >
          <template v-slot:icon>
            <AccountBookOutlined />
          </template>
          <a-menu-item :key="child.path" v-for="child in item.children" @click="navTo(child.path)">{{
            child.name
          }}</a-menu-item>
        </a-sub-menu>
      </a-menu>
    </a-layout-sider>
    <a-layout>
      <a-layout-header style="background: #fff; padding: 0">
        <div
          style="
            display: flex;
            justify-content: flex-end;
            align-items: center;
            height: 100%;
            padding-right: 40px;
          "
        >
          <a-button type="primary" @click="logout">退出登录</a-button>
        </div>
      </a-layout-header>
      <a-layout-content style="margin: 0 16px">
        <div :style="{ minHeight: '100%' }">
          <router-view />
        </div>
      </a-layout-content>
    </a-layout>
  </a-layout>
</template>
<script setup>
import { ref } from "vue";
import { useRouter } from "vue-router";
import list from "@/router/routes";
const menuItems = ref(list);
const router = useRouter();
const collapsed = ref(false);
const selectedKeys = ref(["1"]);
function navTo(url) {
  router.push(url);
}
function logout() {
  localStorage.removeItem("token");
  router.replace("/login");
}

</script>
<style scoped lang="less">
h3 {
  color: white;
  text-align: center;
  padding: 20px 0;
  font-size: 20px;
}
#components-layout-demo-side .logo {
  height: 32px;
  margin: 16px;
  background: rgba(255, 255, 255, 0.3);
}

.site-layout .site-layout-background {
  background: #fff;
}
[data-theme="dark"] .site-layout .site-layout-background {
  background: #141414;
}
</style>