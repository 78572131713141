import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import "@/commom/commom.css"
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/reset.css';
import * as antIcons from '@ant-design/icons-vue';


function initVue() {
    let vueApp = createApp(App);
    let app = vueApp.use(router).use(store).use(Antd);
    //注入权限
    // app.directive('privilege', {
    //     mounted(el, binding) {
    //         privilegeDirective(el, binding);
    //     },
    // });
    // 注册图标组件
    Object.keys(antIcons).forEach((key) => {
        app.component(key, antIcons[key]);
    });
    //全局
    app.config.globalProperties.$antIcons = antIcons;
    //挂载
    app.mount('#app');
}
initVue();

